.wrapper {
  background-color: #fafafa;
  display: flex;
}

.game-container,
.left-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.left-container {
  width: 400px;
  position: fixed;
  right: 0;
  background-color: #fff;
  box-shadow: -2px 0 8px 0 rgba(0,0,0,.1);
  height: 100%;
  overflow: auto;
}

.game-container {
  position: relative;
  width: calc(100% - 400px);
  justify-content: space-between;
}

.stats-container {
  height: 100%;
  /*width: 50%;*/
}
.stats-container h2 {
  text-align: center;
  font-weight: normal;
}

.board-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.chat-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.replay-left-arrow,
.replay-right-arrow {
  position: absolute;
  top: 50%;
}
.replay-left-arrow {
  left: 30px;
}
.replay-right-arrow {
  right: 30px;
}

.score-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.score {
  font-size: 40px;
  font-weight: bold;
  line-height: 66px;
  width: 50px;
  text-align: center;
}

.players-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 20px;
  background-color: #A0E8E6;
}
.players-container .player-one,
.players-container .player-two {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
}
.players-container .played-text {
  font-size: 24px;
}
.players-container .player-one .played-text {
  margin-left: 15px;
}
.players-container .player-two .played-text {
  margin-right: 15px;
}

.word-container {
  text-align: center;
  height: 50px;
  margin-bottom: 10px;
  font-size: 32px;
}

.replay-words-container {
  width: 300px;
  margin-bottom: 30px;
}
.replay-word-container {
  text-align: center;
}
.replay-words-container .word {
  display: inline-block;
  padding: 2px 12px;
}
.replay-words-container .word.active {
  border-radius: 22px;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
  }

  .game-container,
  .left-container {
    min-height: auto;
    width: 100%;
  }

  .score-container {
    margin: 0;
  }
}
